<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text_custom">
          <form action="javascript:void(0)" ref="forget_tracking_number" @submit="forgetTrackingNumber()">
            <div  class="card public-profile border-0 rounded shadow">
              <div class="card-body">
                <h2 class="text-center">Forgot Your Tracking Number!</h2>
                <div v-if="success" class="alert alert-success" role="alert" v-html="success"></div>
                <div v-if="error" class="alert alert-danger" role="alert" v-html="error"></div>
                <b-row>
                  <b-col md="2"></b-col>
                  <b-col md="8">
                    <div class="mb-3">
                      <label class="form-label">Student Name:<span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
<!--                        <i data-feather="user" class="fea icon-sm icons"></i>-->
                        <input name="name" id="tracking-no" type="text" class="form-control ps-5" placeholder="Student name" required>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Date of Birth:<span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
<!--                        <i data-feather="calendar" class="fea icon-sm icons"></i>-->
                        <input name="date_of_birth" id="date-of-birth" type="date" class="form-control ps-5" placeholder="DOB as per certificate" required>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Mobile No:<span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
<!--                        <i data-feather="phone" class="fea icon-sm icons"></i>-->
                        <input name="guardian_mobile" id="phone-no" type="text" class="form-control ps-5" placeholder="(+880)" required>
                      </div>
                    </div>
                    <input type="submit" id="submit" name="send" class="submitBnt btn btn-danger" value="Submit"/>
                  </b-col>
                  <b-col md="2"></b-col>
                </b-row>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import apiCall from "../../../core/apiCall";

export default {
  name: "ForgetTracking",
  data(){
    return{
      success:'',
      error:'',
    }
  },
  methods: {
    forgetTrackingNumber(){
      this.success='';
      this.error='';
      let data=new FormData(this.$refs.forget_tracking_number);
      apiCall.post('/admission/tracking/number/find',data).then((response)=>{
          if(response.data.status==='success') this.success="Your tracking number is: "+response.data.data.track_no;
          else this.error=response.data.message;
      }).catch((error)=>{
        if (error.response.status ===422) {
          Object.keys(error.response.data.errors).map((field) => {
            this.error +=error.response.data.errors[field][0] +'<br>';
          })
        }else this.error=error.response.data.message;
      });
    }
  },
}
</script>

<style scoped>
@import './../../../assets/css/style-dark.css';
</style>