<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div  class="card public-profile border-0 rounded shadow text_custom">
            <div class="card-body">
              <h2 class="text-center">Payment Check/Pay</h2>
              <div v-if="error"  class="alert alert-danger" role="alert">
                <center>{{ error }}</center>
              </div>
              <form method="post" action="javascript:void(0)" @submit="checkPayment()" ref="payment_form">
                <b-row>
                  <b-col md="2"></b-col>
                  <b-col md="8">
                    <div class="mb-3">
                      <label class="form-label">Tracking No:<span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
<!--                        <i data-feather="user" class="fea icon-sm icons"></i>-->
                        <input name="track_no" id="tracking-no" type="text" class="form-control ps-5" placeholder="Tracking number" required>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Mobile No:<span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
<!--                        <i data-feather="phone" class="fea icon-sm icons"></i>-->
                        <input name="guardian_mobile" id="phone-no" type="text" class="form-control ps-5" placeholder="(880)" required>
                      </div>
                    </div>
                    <input type="submit" id="submit" name="send" class="submitBnt btn btn-warning mb-3" value="Submit"/>
                  </b-col>
                  <b-col md="2"></b-col>
                </b-row>

              </form>
              <div v-if="student.name">
                <template v-if="student.is_paid">
                  <span>Status: <strong style="color:green">Success! Your payment is completed.</strong></span>
                </template>
                <template v-else>
                  <span><strong style="color:red">Your payment is not completed. </strong></span><br>
                  <span>Name: <strong style="color:green">{{student.name}}</strong></span><br>
                  <span>Track No: <strong style="color:green">{{student.track_no}}</strong></span><br>
                  <div v-if="moment(admission_schedule.payment_last_time).format('YMMDD')>=moment().format('YMMDD')">
                    <button class="btn btn-primary btn-lg btn-block"
                            id="sslczPayBtn" @click="sslCommerze()" type="submit">Pay Now</button>
                    </div>
                </template>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import apiCall from "../../../core/apiCall";
import moment from "moment"
export default {
name: "Payment",
  props:{
    admission_schedule:{
      required:true,
      type:Object
    }
  },
  data(){
    return{
      moment,
      student:{},
      error:"",
      is_submit:false,
    }
  },
  methods:{
    checkPayment(){
      this.error='';
      let data=new FormData(this.$refs.payment_form);
        apiCall.post('/admission/payment/status/check',data).then((response)=>{
            if(response.data.status==='success'){
                this.student=response.data.data;
                this.$refs.payment_form.reset();
            }else{
              this.student={};
              this.error=response.data.message;
            }
        }).catch(()=>{
          this.student={};
          this.error="Invalid Request";
        })
    },
    async sslCommerze(){
      this.error='';
      let data=new FormData();
      data.append('track_no',this.student.track_no);
      if(this.is_submit == false) {
         this.is_submit=true;
        await apiCall.post('/pay-via-ajax', data).then((response) => {
          this.is_submit= false;
          if (response.data.status === 'success') {
            window.location = response.data.data;
          } else if (response.data.status === 'custom') {
            this.error = response.data.message;
          }
        }).catch(() => {
          this.is_submit= false
          this.error = "Invalid request try again after few minute";
        })
      }
    },
  }
}
</script>
<style scoped>
@import './../../../assets/css/style-dark.css';
</style>
<style scoped>
.paynow{
  margin-left: 1%;
  color: orange;
  font-size: 120%;
}
.paynow:hover{
  color:blue;
  cursor: pointer;
}
</style>