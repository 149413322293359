<template>
    <div>
      <section class="bg-profile d-table w-100 " style="background: url('/images/company/admission.jpeg') center center;">
        <div class="bg-overlay"></div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
              <div class="page-next-level title-heading">
                <div class="page-next">
                  <nav aria-label="breadcrumb" class="d-inline-block">
                    <ul class="breadcrumb bg-custom rounded shadow mb-0">
                      <li class="breadcrumb-item"><a href="#" class="text_custom">Admission</a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div><!--end col-->
          </div><!--end row-->
        </div> <!--end container-->
      </section>
      <section class="section">
        <div class="container">
          <b-tabs content-class="mt-3">
            <b-tab title="Home" active>
              <AdmissionInfo :admission_schedule="admission_schedule"/>
            </b-tab>
            <b-tab title="Application Download">
              <ApplicationDownload/>
            </b-tab>
            <b-tab title="Admit Card">
              <AdmitCard :admission_schedule="admission_schedule"/>
            </b-tab>
            <b-tab title="Payment">
              <Payment :admission_schedule="admission_schedule"/>
            </b-tab>
            <b-tab title="Forgot Tracking">
              <ForgetTracking />
            </b-tab>
          </b-tabs>
        </div>
      </section><!-- Hero End -->
    </div>
</template>

<script>
import feather from 'feather-icons'
//import ApplyOnlineSecond from "@/components/admission/ApplyOnlineSecond";
import AdmissionInfo from "./AdmissionInfo";
import ApplicationDownload from "./ApplicationDownload";
import AdmitCard from "./AdmitCard";
import Payment from "./Payment";
import ForgetTracking from "./ForgetTracking";
import apiCall from "../../../core/apiCall";
import Vue from "vue";
import { TabsPlugin } from 'bootstrap-vue'
Vue.use(TabsPlugin)
export default {
  name: 'ApplyOnlineFirst',
  components: {
    //ApplyOnlineSecond,
    AdmissionInfo,
    ApplicationDownload,
    AdmitCard,
    Payment,
    ForgetTracking
  },
  data () {
      return{
        admission_schedule:{},
      }
  },
  mounted() {
    feather.replace();
  },
  created() {
      this.getInformation();
  },
  methods: {
      async getInformation(){
        await apiCall.get('/get/admission/schedule').then((response)=>{
          this.admission_schedule=response.data;
        }).catch(()=>{
          this.admission_schedule={};
        })
      }
  },
 
}
</script>
<style scoped>
@import './../../../assets/css/style-dark.css';
</style>
<style>
.sub-menu{
  background-color: rgb(255, 255, 255);
}
.sub-menu-item{
  background-color: rgba(117, 200, 229,.4);
  border-radius: 2px;
  border:0;
  width:180px;
  height: 30px;
}
.sub-menu-item:hover{
  background-color: rgba(56, 159, 214,1);
  transition: 0.25s ease-in-out;
  border-radius: 2px;
  color: whitesmoke;
}

.sub-menu-li{
  margin-top:1%;
  margin-left:2%;
  margin-bottom: 1%;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: rgba(230,247,167);
  background-color: rgba(58, 61, 51);
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .nav-link, .nav-tabs .nav-item.show .nav-link {
  color: rgba(230,247,167);
}
</style>