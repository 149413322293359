<template>
<div>
  <!--   Home->(Application Process/ how to apply, 3 pdf link, admission notice pdf-> click, how to payment, apply now btn),
        Application download(field-> tracking no., mobile no), Admit Card(field ->tracking no., mobile no),
        Payment(tracking no., mobile no)   , Forget traking no(field-> st name, dob, mo o.)    -->
  <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div  class="card public-profile border-0 rounded shadow">
            <div class="card-body text_custom">
              <ul>
                  <li class="home-item">
                    <i data-feather="arrow-right" class="fea icon-sm icons home-item-icon"></i>
                    Admission Notice
                    <a class="home-item-text" :href="basePath(instruction.admission_notice)" target="_blank">click to download</a>
                  </li>
                  <li class="home-item">
                    <i data-feather="arrow-right" class="fea icon-sm icons home-item-icon"></i>
                    Application Process
                    <a class="home-item-text" :href="basePath(instruction.admission_instruction)" target="_blank">click to download</a>
                  </li>
                  <li class="home-item">
                    <i data-feather="arrow-right" class="fea icon-sm icons home-item-icon"></i>
                    How to Pay
                    <a class="home-item-text" :href="basePath(instruction.payment_instruction)" target="_blank">click to download</a>
                  </li>
              </ul>
              <div v-if="admission_schedule.admission_start && moment(admission_schedule.admission_start).format('YMMDD')<=moment().format('YMMDD')
                && moment(admission_schedule.admission_end).format('YMMDD')>=moment().format('YMMDD')">
                  <!--        <router-link :to="{name:'applyonlinesecond'}" exact><input type="submit" id="submit" name="send" class="submitBnt btn btn-primary" value="Next"></router-link>      -->
                  <router-link :to="{name:'applyonlinesecond'}">
                    <input type="button" class="btn btn-primary apply-btn" value="Apply Now!"/>
                  </router-link>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import apiCall from "../../../core/apiCall";
import moment from "moment"
export default {
name: "AdmissionInfo",
  props:{
    admission_schedule:{
      required:true,
      type:Object
    }
  },
  data(){
    return{
      moment,
      instruction:{},
    }
  },
  created() {
    this.getInstruction();
  },
  methods:{
    getInstruction(){
        apiCall.get('/get/admission/instruction').then((response)=>{
          this.instruction=response.data;
        }).catch(()=>{
          this.instruction={};
        })
    },
    basePath(path){
      return process.env.VUE_APP_ENV_RESOURCHURL+path;
    }
  }
}
</script>
<style scoped>
@import './../../../assets/css/style-dark.css';
</style>
<style scoped>
.home-item{
  list-style: none;
  margin-left: 1%;
  margin-top: .5%;
  font-size: 150%;
  font-family: 'Roboto Condensed', sans-serif;
}
.home-item-icon{
  width:40px;
  height:30px;
  color: rgb(230, 192, 7);

}
.home-item-text{
  font-size: 50%;
}
.home-item-text:hover{
  cursor: pointer;
}
.apply-btn{
  margin-left: 5%;
}
ul li a{
  color: rgba(230,247,167);
}
</style>
