import { render, staticRenderFns } from "./AdmitCard.vue?vue&type=template&id=1332c058&scoped=true"
import script from "./AdmitCard.vue?vue&type=script&lang=js"
export * from "./AdmitCard.vue?vue&type=script&lang=js"
import style0 from "./AdmitCard.vue?vue&type=style&index=0&id=1332c058&prod&scoped=true&lang=css"
import style1 from "./AdmitCard.vue?vue&type=style&index=1&id=1332c058&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1332c058",
  null
  
)

export default component.exports