<template>
  <section>

    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div  class="card public-profile border-0 rounded shadow text_custom">
            <div class="card-body">
              <h2 class="text-center">Download your application</h2>
              <div v-if="error"  class="alert alert-danger" role="alert">
                <center>{{ error }}</center>
              </div>
              <form method="post" action="javascript:void(0)" @submit="checkPayment()" ref="payment_form">
                <b-row>
                  <b-col md="2"></b-col>
                  <b-col md="8">
                    <div class="mb-3 form-group">
                      <label class="form-label">Tracking No:<span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
<!--                        <i data-feather="user" class="fea icon-sm icons"></i>-->
                        <input name="track_no" id="tracking-no" type="text" class="form-control ps-5" placeholder="Tracking number" required>
                      </div>
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Mobile No:<span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
<!--                        <i data-feather="phone" class="fea icon-sm icons"></i>-->
                        <input name="guardian_mobile" id="phone-no" type="text" class="form-control ps-5" placeholder="(880)" required>
                      </div>
                    </div>
                    <input type="submit" id="submit" name="send" class="submitBnt btn btn-warning mb-3" value="Submit"/>
                  </b-col>
                  <b-col md="2"></b-col>
                </b-row>
              </form>
              <div class="text-left mt-3 admit-card" v-if="student.name">

                <div class="row">
                  <div class="col-lg-4 d-flex justify-content-start">
                    <img :src="student.image" style="width:100px; height:100px" class="rounded" alt="#"/>
                  </div>
                  <div class="col-lg-6 justify-content-center">
                    <span>Name: {{ student.name }}</span><br>
                    <span>Tracking No: {{ student.track_no }}</span><br>
                    <span>Mobile No: {{ student.phone_number }}</span>
                  </div>
                  <div class="col-lg-2 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-start justify-content-xs-start">
                    <input @click="downloadAdmitCard()" type="submit" id="download" name="send" class="submitBnt btn btn-outline-success" value="Download" style="height:40px;margin-top:10%"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import apiCall from "../../../core/apiCall";
import download from "downloadjs";

export default {
  name: "AdmitCard",
  data(){
    return{
      student:{},
      error:"",
    }
  },
  methods:{
    checkPayment(){
      this.error="";
      let data=new FormData(this.$refs.payment_form);
      apiCall.post('/admission/payment/status/check',data).then((response)=>{
        if(response.data.status==='success'){
          this.student=response.data.data;
          this.$refs.payment_form.reset();
        }else{
          this.error=response.data.message;
        }
      }).catch(()=>{
        this.error="Invalid Request";
      })
    },
    downloadAdmitCard(){
      this.error="";
      let data=new FormData();
      data.append('track_no',this.student.track_no);
      data.append('mobile_no',this.student.phone_number);
      apiCall.post('/admission/application/download',data,{responseType: 'blob'}).then((response)=>{
        if(response.status===200){
          this.student={};
          const content = response.headers['content-type'];
          download(response.data,'student_information.pdf', content)
        }else{
          this.error=response.data.message;
        }
      }).catch(()=>{
        this.error="Invalid Request";
      })
    }
  }
}
</script>
<style scoped>
@import './../../../assets/css/style-dark.css';
</style>
<style scoped>
.admit-card{
  border:1px solid green;
  padding:1%;
  border-radius: 5px;
  box-shadow: 2px 3px 4px 2px rgba(49, 51, 53,.5);
}
</style>